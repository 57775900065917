<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="row">
        <div class="col-md-12">
          <div class="card-header">
            <h3 class="card-title"> Edit Staff </h3>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3">&nbsp;</div>
          <div class="col-lg-6 connectedSortable">
            <div class="card">
              <div class="card-body">           
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">
                    <label>Staff Name</label>
                    <br>
                    <input type="text" class="form-control" readonly name="staffname" id="staffname" v-model="staffname"/>
                    <br>
                    <label>Staff Role</label>
                    <br>
<select v-model="roleId" class="form-control">
<option v-for="role in rolelist" :key="role.id" :value="role.id"> {{role.role_name}} </option>
</select>
                    <br>
                    <label>Status</label>
                    <div class="example ex1">                          
<label class="radio green">                          
<input type="radio" v-model="status" name="status" value="1" checked>
<span>Active</span>
</label>
                          
<label class="radio green">                          
<input type="radio" v-model="status" name="status" value="0">
<span>Deactive</span>
</label>
</div>
<br>
<button v-on:click="editStaff()" class="btn btn-success">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {  
  extends:defineComponent,
    components:{                 
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  name: 'editservice', 
     
    data(){
        return {
            rolelist:[],
            company_id:this.$store.state.auth.user.data[0].company_id,              
            user_id:this.$store.state.auth.user.data[0].id,
            staff_id: this.$route.query.staff_id,
            name: this.name, 
            role_id: this.roleId, 
            staffname:this.staffname,
            status:this.status,
            roleId:0             
        }
    },

    methods: {
    showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
    showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
    async getData() {
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/get_staff_edit`, {
          company_id: this.company_id, 
          staff_id: this.$route.query.staff_id         
        }) 
    this.staffname = response.data.data[0].user_info[0].name
    this.roleId = response.data.data[0].user_info[0].role_id   
    this.status = response.data.data[0].user_info[0].status       
    this.resources = response.data.data[0].user_info;      
    console.log(this.roleId)
    },
    async getRolelist() {
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/getrole_list`, {
          company_id: this.company_id, 
          user_id: this.user_id,         
        })      
    this.name = response.data.data[0].role_name;      
    this.rolelist = response.data.data;
    //this.roleId = response.data.data[0].id;      
    //console.log(response.data.data[0])
    },
    editStaff(){
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/staff_edit_data', {
              company_id: this.company_id,
              user_id: this.user_id,
              staff_id: this.staff_id,
              name: this.staffname,
              role_id: this.roleId,
              status: this.status
              }, 
              {
              Company_id: this.Company_id,
              user_id: this.user_id,
              staff_id: this.staff_id,
              name: this.staffname,
              role_id: this.roleId,
              status: this.status
            })
                .then(response => {   
                  this.showsuccessmsg(response.data.response);                  
                  //console.log(response.data);                               
                  //this.$router.push({path:'/extrachargelist'})
                  //return response
                }).catch(error => { 
                  this.showfailedmsg('Something went wrong');       
                  console.log(error)        
      });
        }
    },
     mounted(){     
        this.getData();
        this.getRolelist();        
    }
}
</script>
<style>  
  @import '../../assets/dist/css/custom.css'  
</style>